<template>
  <section id="hero">
    <v-img
      :src="require('@/assets/banner-home-dashboard.jpg')"
      class="white--text rounded-lg mx-2 ma-2"
      max-height="350"
    >
    <!-- gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)" -->
    </v-img>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',
    data: () => ({
      prefix: 'B',
      bottom_sheet: false,
      platnomors: [],
      loading: false,
      form: {
        name: '',
        vehicle: '',
        zipcode: '',
      },
    }),
    provide: {
      theme: { isDark: true },
    },
     // Check If Back Button Press, Modal Close
    beforeRouteLeave (to, from, next) {
        if (this.bottom_sheet) {
            this.bottom_sheet = false
            next(false)
        } else {
            next()
        }
    },
    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'
        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },
    methods: {
      RegisterVeh () {
        this.loading = true
        this.bottom_sheet = true
        this.$axios.post(this.$functions.SafeURL('apiListGenTableSimple', `gendtab_actived=Y&gendtab_refid=V08&order_by=gendtab_id&order_type=ASC`))
        .then(response => {
            this.platnomors = response.data
            this.loading = false
        })
        .catch(e => {
            this.loading = false
            alert(e)
        })
      },
      GetData (id) {
        this.prefix = id
        this.bottom_sheet = false
        setTimeout(function(){
        document.getElementById('vehicle_reg').focus()
        }, 500)
      },
      Epolicy (id) {
        if (id === 'safari') {
          if (this.form.name !== '') {
            this.$router.push({name: 'polistakaful', params: { type: 'safari', id: this.form.name }})
          }
        } else if (id === 'vehicle') {
          if (this.form.vehicle !== '') {
            this.$router.push({name: 'polistakaful', params: { type: 'vehicle', id: this.prefix, data:this.form.vehicle }})
          }
        } else if (id === 'property') {
          if (this.form.zipcode !== '') {
            this.$router.push({name: 'polistakaful', params: { type: 'property', id: this.form.zipcode }})
          }
        }
      }
    }
  }
</script>
